@import url("https://fonts.googleapis.com/css2?family=Anton&family=Montserrat&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Bungee+Shade&family=Julius+Sans+One&family=Montserrat&family=Roboto+Condensed:wght@300;400&family=Wire+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Bodoni+Moda:wght@400;500&family=Bungee+Shade&family=Julius+Sans+One&family=Montserrat&family=Roboto+Condensed:wght@300;400&family=Wire+One&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body,
#root {
  position: relative;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  background-color: #060606;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: "Roboto Condensed", sans-serif;
}

a {
  text-decoration: none;
}

/// Grid CSS Featured & Directors
.grid-wrapper {
  display: grid;
  margin: 0 auto;
  gap: 5px;
}
.grid-wrapper a div {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
}
.grid-wrapper > div > a {
  text-decoration: none;
  text-align: center;
}

.photo-folder-page {
  position: relative;
  display: flex;
  justify-content: center;
  .row {
    display: flex;
    .column {
      img {
        width: 100%;
        height: auto;
        display: block;
        margin-top: 5px;
      }
    }
  }
  .logo-bbkk {
    position: absolute;
    z-index: 1000;
    img {
      width: 100%;
    }
  }
}
.times {
  position: absolute;
  z-index: 999;
  img {
    width: 100%;
    filter: invert(1);
  }
}

/// Awards & Contact
.awards-container,
.contact-container {
  width: 100%;
  min-height: 100vh;
}
.topsection-container {
  height: 100vh;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
}
.awards-container .awards,
.contact-container .cards {
  width: 100%;
  h1 {
    color: whitesmoke;
  }
  h3 {
    color: whitesmoke;
    font-weight: 100;
  }
}

/// EmbeddedPlayer
.embedded_player {
  position: relative;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #001f32;
  .embedded-player {
    position: relative;
    iframe {
      width: 100%;
      height: 100%;
    }
    .player-text {
      display: none;
    }
  }
}
/// EmbeddedPlayer - Carrousel
.embedded_player_special {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #001f32;
  .button-player-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    .arrow-button.left {
      position: absolute;
      left: 0;
      margin-left: 6rem;
      rotate: 180deg;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: #f8f0e5;
    }
    .arrow-button.right {
      position: absolute;
      right: 0;
      margin-right: 6rem;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: #f8f0e5;
    }
    .embedded-player-special {
      position: absolute;
      width: 60vw;
      // max-width: 800px;
      aspect-ratio: 16 / 9;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

/// Header
.header-container {
  position: absolute;
  width: 100vw;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 2000;
}
.header-left-side {
  position: relative;
  list-style-type: none;
  padding: 0;
  a {
    color: whitesmoke;
    text-decoration: none;
  }
  a.commercial-btn.active {
    color: #75d5fd;
    transition: 350ms ease-in-out;
  }
  a.fiction-btn.active {
    color: #75d5fd;
    transition: 350ms ease-in-out;
  }
}
.bbk-logo {
  position: relative;
}
.header-right-side {
  position: relative;
  list-style-type: none;
  padding: 0;
  a {
    color: whitesmoke;
    text-decoration: none;
  }
  a.about-btn.active {
    color: #75d5fd;
    transition: 350ms ease-in-out;
  }
  a.contact-btn.active {
    color: #75d5fd;
    transition: 350ms ease-in-out;
  }
}

///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
  .desktop-container {
    display: none;
  }
  .grid-wrapper a div {
    // font-size: 21px;
    font-size: 27.5px;
  }
  .hover-thumbnails {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 27.5px;
    color: whitesmoke;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    > * {
      transform: translateY(30px);
      transition: transform 0.5s;
    }
  }
  .hover-options {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    a {
      background: #ffffff;
      opacity: 0.6;
      color: #000;
      padding: 0.4rem 0.8rem;
      border-radius: 4px;
      text-decoration: none;
      font-size: 16px;
    }
  }

  .photo-folder-page {
    .row {
      flex-direction: column;
      flex-wrap: nowrap;
      margin-top: 7rem;
      padding: 0 0px;
      .column {
        flex: 100%;
      }
    }
    .logo-bbkk {
      width: 20vw;
      top: 0;
      margin-top: 10%;
    }
  }

  .times {
    width: 5vw;
    top: 0;
    margin-top: 10%;
    right: 8%;
    opacity: 1;
  }
  .embedded-player {
    width: 85vw;
    height: 30vh;
    bottom: 5rem;
  }
  .mobile-container {
    position: fixed;
    display: flex;
    align-items: flex-end;
    width: 100%;
    min-height: 11.5%;
  }
  .mobile-container:after {
    content: "";
    position: absolute;
    background: #060603;
    border-bottom: 1px solid #f5a1a2ba;
    bottom: 0;
    left: 5%;
    right: 5%;
    width: 90%;
  }
  #mobile-container {
    background: linear-gradient(90deg, #060603, #060606);
  }
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    width: 87%;
    margin: 0 auto;
    margin-bottom: 5px;
    padding: 0;
    list-style-type: none;
    a {
      color: whitesmoke;
      font-size: 13px;
      text-decoration: none;
      letter-spacing: 0.09em;
    }
    a.commercial-btn.active {
      color: #75d5fd;
      transition: 350ms ease-in-out;
    }
    a.fiction-btn.active {
      color: #75d5fd;
      transition: 350ms ease-in-out;
    }
    a.about-btn.active {
      color: #75d5fd;
      transition: 350ms ease-in-out;
    }
    a.contact-btn.active {
      color: #75d5fd;
      transition: 350ms ease-in-out;
    }
  }
  .nav-buttons > li:nth-child(1) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 87%;
  }
  .bbk-logo-mobile {
    position: absolute;
    width: 22vw;
    margin-bottom: 0.5rem;
    img {
      width: 100%;
    }
  }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
  .grid-wrapper a div {
    font-size: 19px;
  }

  .hover-thumbnails {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 19px;
    color: whitesmoke;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    > * {
      transform: translateY(30px);
      transition: transform 0.5s;
    }
  }
  .hover-options {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.75rem;
    a {
      background: #ffffff;
      opacity: 0.6;
      color: #000;
      padding: 0.3rem 0.7rem;
      border-radius: 4px;
      text-decoration: none;
      font-size: 14px;
    }
  }

  .photo-folder-page {
    .row {
      padding: 0 0px;
      flex-wrap: wrap;
      margin-top: 5.5rem;
      .column {
        flex: 33.33%;
      }
    }
    .logo-bbkk {
      width: 10vw;
      top: 0;
      margin-top: 4%;
    }
  }

  .times {
    width: 2vw;
    top: 0;
    margin-top: 4%;
    right: 4%;
    opacity: 1;
  }
  .embedded-player {
    width: 50vw;
    height: 55vh;
    iframe {
      transform: scale(1.1);
    }
  }
  .desktop-container {
    position: fixed;
    display: flex;
    align-items: center;
    height: 16.5vh;
  }
  .desktop-container-colored {
    background: linear-gradient(90deg, #060603, #060606);
  }
  .header-left-side {
    display: flex;
    justify-content: flex-end;
    width: 45vw;
    a {
      font-size: 13.5px;
      font-weight: 100;
      letter-spacing: 0.1em;
    }
  }
  .header-left-side > li:nth-child(1) {
    position: relative;
    right: 4rem;
  }
  .header-left-side > li:nth-child(2) {
    position: relative;
    right: 2rem;
  }
  .bbk-logo {
    display: flex;
    width: 10vw;
    img {
      width: 100%;
    }
  }
  .header-right-side {
    display: flex;
    justify-content: flex-start;
    width: 45vw;
    a {
      font-size: 13.5px;
      font-weight: 100;
      letter-spacing: 0.1em;
    }
  }
  .header-right-side > li:nth-child(2) {
    position: relative;
    left: 4rem;
  }
  .header-right-side > li:nth-child(1) {
    position: relative;
    left: 2rem;
  }
  .bbk-logo-mobile {
    display: none;
  }
  .mobile-container {
    display: none;
  }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
  .grid-wrapper a div {
    font-size: 25px;
  }
  .hover-thumbnails {
    position: absolute;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: whitesmoke;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
  }
  .hover-options {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.75rem;
    a {
      background: #ffffff;
      opacity: 0.6;
      color: #000;
      padding: 0.3rem 0.7rem;
      border-radius: 4px;
      text-decoration: none;
      font-size: 14px;
    }
  }

  .photo-folder-page {
    .row {
      padding: 0 0px;
      flex-wrap: wrap;
      margin-top: 8.5rem;
      .column {
        flex: 50%;
      }
    }
    .logo-bbkk {
      width: 15vw;
      top: 0;
      margin-top: 7%;
    }
  }
  .times {
    width: 3.5vw;
    top: 0;
    margin-top: 7%;
    right: 2rem;
    opacity: 1;
  }

  .embedded-player {
    width: 85vw;
    height: 35vh;
    bottom: 5rem;
  }
  .desktop-container {
    display: none;
  }
  .mobile-container {
    position: fixed;
    display: flex;
    align-items: flex-end;
    width: 100%;
    min-height: 11.5%;
  }
  .mobile-container:after {
    content: "";
    position: absolute;
    border-bottom: 1px solid #f5a1a2ba;
    bottom: 0;
    left: 5%;
    right: 5%;
    width: 90%;
  }
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    width: 87%;
    margin: 0 auto;
    margin-bottom: 5px;
    padding: 0;
    list-style-type: none;
    a {
      color: whitesmoke;
      font-size: 21.5px;
      text-decoration: none;
      letter-spacing: 0.09em;
    }
    a.commercial-btn.active {
      color: #75d5fd;
      transition: 350ms ease-in-out;
    }
    a.fiction-btn.active {
      color: #75d5fd;
      transition: 350ms ease-in-out;
    }
    a.about-btn.active {
      color: #75d5fd;
      transition: 350ms ease-in-out;
    }
    a.contact-btn.active {
      color: #75d5fd;
      transition: 350ms ease-in-out;
    }
  }
  .nav-buttons > li:nth-child(1) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 87%;
  }
  .bbk-logo-mobile {
    position: fixed;
    width: 19vw;
    margin-bottom: 0.5rem;
    img {
      width: 100%;
    }
  }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
  .grid-wrapper a div {
    font-size: 25px;
  }

  .hover-thumbnails {
    position: absolute;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    color: whitesmoke;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
  }
  .hover-options {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.75rem;
    a {
      background: #ffffff;
      opacity: 0.6;
      color: #000;
      padding: 0.3rem 0.7rem;
      border-radius: 4px;
      text-decoration: none;
      font-size: 14px;
    }
  }

  .photo-folder-page {
    .row {
      padding: 0 0px;
      // flex-direction: column;
      flex-wrap: wrap;
      margin-top: 8.5rem;
      .column {
        flex: 50%;
      }
    }
    .logo-bbkk {
      width: 15vw;
      top: 0;
      margin-top: 5%;
    }
  }

  .times {
    width: 2.5vw;
    top: 0;
    margin-top: 5%;
    right: 2.5rem;
    opacity: 1;
  }

  .embedded-player {
    width: 65vw;
    height: 55vh;
    iframe {
      transform: scale(1.1);
    }
  }
  .mobile-container {
    display: none;
  }
  .bbk-logo-mobile {
    display: none;
  }
  .desktop-container {
    position: fixed;
    display: flex;
    align-items: center;
    height: 15vh;
  }
  .desktop-container-colored {
    background: linear-gradient(90deg, #060603, #060606);
  }
  .header-left-side {
    display: flex;
    justify-content: flex-end;
    width: 45vw;
    a {
      color: whitesmoke;
      font-size: 19px;
      font-weight: 100;
      letter-spacing: 0.1em;
      text-decoration: none;
    }
  }
  .header-left-side > li:nth-child(1) {
    position: relative;
    right: 4rem;
  }
  .header-left-side > li:nth-child(2) {
    position: relative;
    right: 2rem;
  }
  .bbk-logo {
    display: flex;
    width: 10vw;
    img {
      width: 100%;
    }
  }
  .header-right-side {
    display: flex;
    justify-content: flex-start;
    width: 45vw;
    transform: scale(1);
    a {
      color: whitesmoke;
      font-size: 19px;
      font-weight: 100;
      letter-spacing: 0.1em;
      text-decoration: none;
    }
  }
  .header-right-side > li:nth-child(2) {
    position: relative;
    left: 4rem;
  }
  .header-right-side > li:nth-child(1) {
    position: relative;
    left: 2rem;
  }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
  .grid-wrapper a div {
    position: relative;
    font-size: 27.5px;
  }
  ///HOVER EFFECT GRID AREA'S
  .hover-thumbnails {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.4);
    transition: opacity 0.25s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 27.5px;
    color: whitesmoke;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    &:hover {
      opacity: 1;
      backdrop-filter: blur(5px);
      transition: ease-in-out 350ms;
    }
    > * {
      transform: translateY(30px);
      transition: transform 0.5s;
    }
  }
  .hover-thumbnails:hover > * {
    transform: translateY(0);
  }
  .hover-options {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;

    a {
      background: #ffffff;
      opacity: 0.4;
      color: #000;
      padding: 0.4rem 0.8rem;
      border-radius: 4px;
      text-decoration: none;
      font-size: 16px;
      transition: opacity 0.25s;
      &:hover {
        background: #ddd;
        opacity: 1;
        transition: ease-in-out 350ms;
      }
    }
  }

  .photo-folder-page {
    .row {
      padding: 0 0px;
      flex-wrap: wrap;
      margin-top: 8.5rem;
      .column {
        flex: 33.33%;
        padding: 0 2px;
      }
    }
    .logo-bbkk {
      width: 10vw;
      top: 0;
      margin-top: 2.5%;
    }
  }
  .times {
    width: 1.5vw;
    top: 0;
    margin-top: 3%;
    right: 4%;
    opacity: 1;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
      transform: rotate(180deg);
      transition: 300ms linear;
    }
  }

  .embedded-player {
    width: 55vw;
    height: 60vh;
    margin-top: 4%;
    iframe {
      transform: scale(1.2);
    }
  }
  .mobile-container {
    display: none;
  }
  .bbk-logo-mobile {
    display: none;
  }
  .desktop-container {
    position: fixed;
    display: flex;
    align-items: center;
    height: 15vh;
  }
  .desktop-container-colored {
    background: linear-gradient(90deg, #060603, #060606);
  }
  .header-left-side {
    display: flex;
    justify-content: flex-end;
    width: 45vw;
    a {
      color: whitesmoke;
      font-size: 19px;
      font-weight: 400;
      letter-spacing: 0.1em;
      text-decoration: none;
      &:hover {
        color: #f5a1a3;
        transition: 400ms ease-in-out;
      }
    }
  }
  .header-left-side > li:nth-child(1) {
    position: relative;
    right: 8rem;
  }
  .header-left-side > li:nth-child(2) {
    position: relative;
    right: 4rem;
  }
  .bbk-logo {
    display: flex;
    width: 10vw;
    img {
      width: 100%;
    }
  }
  .header-right-side {
    display: flex;
    justify-content: flex-start;
    width: 45vw;
    a {
      color: whitesmoke;
      font-size: 19px;
      font-weight: 400;
      letter-spacing: 0.1em;
      text-decoration: none;
      &:hover {
        color: #f5a1a3;
        transition: 400ms ease-in-out;
      }
    }
  }
  .header-right-side > li:nth-child(2) {
    position: relative;
    left: 8rem;
  }
  .header-right-side > li:nth-child(1) {
    position: relative;
    left: 4rem;
  }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
  .grid-wrapper a div {
    font-size: 27.5px;
  }
  ///HOVER EFFECT GRID AREA'S
  .hover-thumbnails {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.4);
    transition: opacity 0.25s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 27.5px;
    color: whitesmoke;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    &:hover {
      opacity: 1;
      backdrop-filter: blur(5px);
      transition: ease-in-out 350ms;
    }
    > * {
      transform: translateY(30px);
      transition: transform 0.5s;
    }
  }
  .hover-thumbnails:hover > * {
    transform: translateY(0);
  }
  .hover-options {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;

    a {
      background: #ffffff;
      opacity: 0.4;
      color: #000;
      padding: 0.4rem 0.8rem;
      border-radius: 4px;
      text-decoration: none;
      font-size: 16px;
      transition: opacity 0.25s;
      &:hover {
        background: #ddd;
        opacity: 1;
        transition: ease-in-out 350ms;
      }
    }
  }

  .photo-folder-page {
    .row {
      padding: 0 0px;
      flex-wrap: wrap;
      margin-top: 8.5rem;
      .column {
        flex: 33.33%;
        padding: 0 2px;
      }
    }
    .logo-bbkk {
      width: 10vw;
      top: 0;
      margin-top: 2.5%;
    }
  }
  .times {
    width: 1.5vw;
    top: 0;
    margin-top: 5%;
    right: 4%;
    opacity: 1;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
      transform: rotate(180deg);
      transition: 300ms linear;
    }
  }
  .embedded-player {
    width: 55vw;
    height: 60vh;
    margin-top: 4%;
    iframe {
      transform: scale(1.2);
    }
  }
  .mobile-container {
    display: none;
  }
  .bbk-logo-mobile {
    display: none;
  }
  .desktop-container {
    position: fixed;
    display: flex;
    align-items: center;
    height: 10vh;
    margin-top: 15px;
  }
  .desktop-container-colored {
    background: linear-gradient(90deg, #060603, #060606);
  }
  .header-left-side {
    display: flex;
    justify-content: flex-end;
    width: 45vw;
    a {
      color: whitesmoke;
      font-size: 19px;
      font-weight: 400;
      letter-spacing: 0.1em;
      text-decoration: none;
      &:hover {
        color: #f5a1a3;
        transition: 400ms ease-in-out;
      }
    }
  }
  .header-left-side > li:nth-child(1) {
    position: relative;
    right: 8rem;
  }
  .header-left-side > li:nth-child(2) {
    position: relative;
    right: 4rem;
  }
  .bbk-logo {
    display: flex;
    width: 10vw;
    img {
      width: 90%;
    }
  }
  .header-right-side {
    display: flex;
    justify-content: flex-start;
    width: 45vw;
    a {
      color: whitesmoke;
      font-size: 19px;
      font-weight: 400;
      letter-spacing: 0.1em;
      text-decoration: none;
      &:hover {
        color: #f5a1a3;
        transition: 400ms ease-in-out;
      }
    }
  }
  .header-right-side > li:nth-child(2) {
    position: relative;
    left: 8rem;
  }
  .header-right-side > li:nth-child(1) {
    position: relative;
    left: 4rem;
  }
}
