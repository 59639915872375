a.dropdown-menu__link.achmed.active,
a.dropdown-menu__link.blue.active,
a.dropdown-menu__link.damien.active,
a.dropdown-menu__link.folkert.active,
a.dropdown-menu__link.joosje.active,
a.dropdown-menu__link.mariabodil.active,
a.dropdown-menu__link.shay.active,
a.dropdown-menu__link.simon.active,
a.dropdown-menu__link.bram.active {
    color: #f5a1a3;
    opacity: 1;
    transition: 350ms ease-in-out;
}
///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
    .dropdown-menu {
        position: fixed;
        left: 50%;
        top: 13%;
        transform: translate(-50%, -13%);
        z-index: 10;
    }
    .dropdown-menu__toggle {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        letter-spacing: 0.035em;
        padding: 0;
        font-family: "Roboto Condensed", sans-serif;
        transition: opacity 0.8s ease, visibility 0.8s ease, transform 0.8s ease;
        span {
            font-size: 19px;
            color: whitesmoke;
        }
    }
    .shadowed-text {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
    .dropdown-menu__arrow {
        border: solid white;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 5px;
        transform: rotate(45deg);
        transition: transform 0.4s ease;
    }
    .dropdown-menu__arrow.open {
        transform: rotate(-135deg);
        transition: transform 0.4s ease;
    }
    .dropdown-menu__list {
        position: fixed;
        width: 250px;
        // top: 100%;
        // left: 0;
        list-style: none;
        padding: 0;
        margin: 0;
        // background-color: #00000021;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        // opacity: 0;
        // visibility: hidden;
        // transform: translateY(-10px);
        // transition: opacity 0.8s ease-in-out, visibility 0.8s ease-in-out, transform 0.8s ease-in-out;
        font-family: "Roboto Condensed", sans-serif;
        border-radius: 3.5px;
    }
    .dropdown-menu__list.open {
        opacity: 1;
        visibility: visible;
        transform: translateY(10px);
        // transition: opacity 0.8s ease-in-out, visibility 0.8s ease-in-out, transform 0.8s ease-in-out;
    }
    .dropdown-menu__item {
        font-size: 18px;
        margin-bottom: 2.5px;
    }
    .dropdown-menu li:nth-child(1) {
        margin: 2px 0px 2.5px 0px;
    }
    .dropdown-menu__link {
        color: whitesmoke;
        text-decoration: none;
    }
}
