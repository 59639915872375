///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-mariabodil {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(14, 90vh);
  }
  .three-mariabodil {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .one-mariabodil {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .two-mariabodil {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .four-mariabodil {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  .one-thumbnail {
    grid-column: 1/2;
    grid-row: 5/6;
  }
  .two-thumbnail {
    grid-column: 1/2;
    grid-row: 6/7;
  }
  .three-thumbnail {
    grid-column: 1/2;
    grid-row: 7/8;
  }
  .four-thumbnail {
    grid-column: 1/2;
    grid-row: 8/9;
  }
  .five-thumbnail {
    grid-column: 1/2;
    grid-row: 9/10;
  }
  .six-thumbnail {
    grid-column: 1/2;
    grid-row: 10/11;
  }
  .seven-thumbnail {
    grid-column: 1/2;
    grid-row: 11/12;
  }
  .eight-thumbnail {
    grid-column: 1/2;
    grid-row: 12/13;
  }
  .nine-thumbnail {
    grid-column: 1/2;
    grid-row: 13/14;
  }
  .ten-thumbnail {
    grid-column: 1/2;
    grid-row: 14/15;
  }
  //CARD ONE
  #grid-wrapper-fiction-mariabodil > div.one-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-fiction-mariabodil > div.two-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-mariabodil > div.three-mariabodil > div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-fiction-mariabodil > div.four-mariabodil > a > div {
    background-size: cover;
    background-position: center;
  }
  /// CARD ONE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.one-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD TWO-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.two-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD THREE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.three-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD FOUR-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.four-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD FIVE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.five-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD SIX-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.six-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD SEVEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.seven-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD EIGHT-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.eight-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD NINE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.nine-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD TEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.ten-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
  #grid-wrapper-fiction-mariabodil {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(12, 200px);
  }
  /// 2 BIG
  .three-mariabodil {
    grid-column: 1/4;
    grid-row: 1/3;
  }
  .one-mariabodil {
    grid-column: 4/7;
    grid-row: 1/3;
  }
  /// 3 SMALL
  .two-mariabodil {
    grid-column: 1/3;
    grid-row: 3/5;
  }
  .four-mariabodil {
    grid-column: 3/5;
    grid-row: 3/5;
  }
  .one-thumbnail {
    grid-column: 5/7;
    grid-row: 3/5;
  }
  /// 2 BIG
  .two-thumbnail {
    grid-column: 1/4;
    grid-row: 5/7;
  }
  .three-thumbnail {
    grid-column: 4/7;
    grid-row: 5/7;
  }
  /// 3 SMALL
  .four-thumbnail {
    grid-column: 1/3;
    grid-row: 7/9;
  }
  .five-thumbnail {
    grid-column: 3/5;
    grid-row: 7/9;
  }
  .six-thumbnail {
    grid-column: 5/7;
    grid-row: 7/9;
  }
  /// 2 BIG
  .seven-thumbnail {
    grid-column: 1/4;
    grid-row: 9/11;
  }
  .eight-thumbnail {
    grid-column: 4/7;
    grid-row: 9/11;
  }
  /// 2 BIG
  .nine-thumbnail {
    grid-column: 1/4;
    grid-row: 11/13;
  }
  .ten-thumbnail {
    grid-column: 4/7;
    grid-row: 11/13;
  }

  //CARD ONE
  #grid-wrapper-fiction-mariabodil > div.one-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-fiction-mariabodil > div.two-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-mariabodil > div.three-mariabodil > div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-fiction-mariabodil > div.four-mariabodil > a > div {
    background-size: cover;
    background-position: 25%;
  }
  /// CARD ONE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.one-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD TWO-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.two-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD THREE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.three-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD FOUR-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.four-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD FIVE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.five-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD SIX-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.six-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD SEVEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.seven-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD EIGHT-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.eight-thumbnail > a > div {
    background-size: cover;
    background-position: center 30%;
    background-repeat: no-repeat;
  }
  /// CARD NINE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.nine-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD TEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.ten-thumbnail > a > div {
    background-size: cover;
    background-position: center 35%;
    background-repeat: no-repeat;
  }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-mariabodil {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(13, 200px);
  }
  /// 2 BIG
  .three-mariabodil {
    grid-column: 1/4;
    grid-row: 2/4;
  }
  .one-mariabodil {
    grid-column: 4/7;
    grid-row: 2/4;
  }
  /// 3 SMALL
  .two-mariabodil {
    grid-column: 1/3;
    grid-row: 4/6;
  }
  .four-mariabodil {
    grid-column: 3/5;
    grid-row: 4/6;
  }
  .one-thumbnail {
    grid-column: 5/7;
    grid-row: 4/6;
  }
  /// 2 BIG
  .two-thumbnail {
    grid-column: 1/4;
    grid-row: 6/8;
  }
  .three-thumbnail {
    grid-column: 4/7;
    grid-row: 6/8;
  }
  /// 3 SMALL
  .four-thumbnail {
    grid-column: 1/3;
    grid-row: 8/10;
  }
  .five-thumbnail {
    grid-column: 3/5;
    grid-row: 8/10;
  }
  .six-thumbnail {
    grid-column: 5/7;
    grid-row: 8/10;
  }
  /// 2 BIG
  .seven-thumbnail {
    grid-column: 1/4;
    grid-row: 10/12;
  }
  .eight-thumbnail {
    grid-column: 4/7;
    grid-row: 10/12;
  }
  /// 2 BIG
  .nine-thumbnail {
    grid-column: 1/4;
    grid-row: 12/14;
  }
  .ten-thumbnail {
    grid-column: 4/7;
    grid-row: 12/14;
  }

  //CARD ONE
  #grid-wrapper-fiction-mariabodil > div.one-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-fiction-mariabodil > div.two-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-mariabodil > div.three-mariabodil > div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-fiction-mariabodil > div.four-mariabodil > a > div {
    background-size: cover;
    background-position: 25%;
  }
  /// CARD ONE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.one-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD TWO-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.two-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD THREE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.three-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD FOUR-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.four-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD FIVE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.five-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD SIX-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.six-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD SEVEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.seven-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD EIGHT-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.eight-thumbnail > a > div {
    background-size: cover;
    background-position: center 30%;
    background-repeat: no-repeat;
  }
  /// CARD NINE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.nine-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD TEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.ten-thumbnail > a > div {
    background-size: cover;
    background-position: center 35%;
    background-repeat: no-repeat;
  }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
  #grid-wrapper-fiction-mariabodil {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(13, 200px);
  }
  /// 2 BIG
  .three-mariabodil {
    grid-column: 1/4;
    grid-row: 2/4;
  }
  .one-mariabodil {
    grid-column: 4/7;
    grid-row: 2/4;
  }
  /// 3 SMALL
  .two-mariabodil {
    grid-column: 1/3;
    grid-row: 4/6;
  }
  .four-mariabodil {
    grid-column: 3/5;
    grid-row: 4/6;
  }
  .one-thumbnail {
    grid-column: 5/7;
    grid-row: 4/6;
  }
  /// 2 BIG
  .two-thumbnail {
    grid-column: 1/4;
    grid-row: 6/8;
  }
  .three-thumbnail {
    grid-column: 4/7;
    grid-row: 6/8;
  }
  /// 3 SMALL
  .four-thumbnail {
    grid-column: 1/3;
    grid-row: 8/10;
  }
  .five-thumbnail {
    grid-column: 3/5;
    grid-row: 8/10;
  }
  .six-thumbnail {
    grid-column: 5/7;
    grid-row: 8/10;
  }
  /// 2 BIG
  .seven-thumbnail {
    grid-column: 1/4;
    grid-row: 10/12;
  }
  .eight-thumbnail {
    grid-column: 4/7;
    grid-row: 10/12;
  }
  /// 2 BIG
  .nine-thumbnail {
    grid-column: 1/4;
    grid-row: 12/14;
  }
  .ten-thumbnail {
    grid-column: 4/7;
    grid-row: 12/14;
  }

  //CARD ONE
  #grid-wrapper-fiction-mariabodil > div.one-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-fiction-mariabodil > div.two-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-mariabodil > div.three-mariabodil > div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-fiction-mariabodil > div.four-mariabodil > a > div {
    background-size: cover;
    background-position: 25%;
  }
  /// CARD ONE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.one-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD TWO-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.two-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD THREE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.three-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD FOUR-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.four-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD FIVE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.five-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD SIX-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.six-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD SEVEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.seven-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD EIGHT-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.eight-thumbnail > a > div {
    background-size: cover;
    background-position: center 30%;
    background-repeat: no-repeat;
  }
  /// CARD NINE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.nine-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD TEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.ten-thumbnail > a > div {
    background-size: cover;
    background-position: center 35%;
    background-repeat: no-repeat;
  }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
  #grid-wrapper-fiction-mariabodil {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(19, 200px);
  }
  /// 2 BIG
  .three-mariabodil {
    grid-column: 1/4;
    grid-row: 2/5;
  }
  .one-mariabodil {
    grid-column: 4/7;
    grid-row: 2/5;
  }
  /// 3 SMALL
  .two-mariabodil {
    grid-column: 1/3;
    grid-row: 5/8;
  }
  .four-mariabodil {
    grid-column: 3/5;
    grid-row: 5/8;
  }
  .one-thumbnail {
    grid-column: 5/7;
    grid-row: 5/8;
  }
  /// 2 BIG
  .two-thumbnail {
    grid-column: 1/4;
    grid-row: 8/11;
  }
  .three-thumbnail {
    grid-column: 4/7;
    grid-row: 8/11;
  }
  /// 3 SMALL
  .four-thumbnail {
    grid-column: 1/3;
    grid-row: 11/14;
  }
  .five-thumbnail {
    grid-column: 3/5;
    grid-row: 11/14;
  }
  .six-thumbnail {
    grid-column: 5/7;
    grid-row: 11/14;
  }
  /// 2 BIG
  .seven-thumbnail {
    grid-column: 1/4;
    grid-row: 14/17;
  }
  .eight-thumbnail {
    grid-column: 4/7;
    grid-row: 14/17;
  }
  /// 2 BIG
  .nine-thumbnail {
    grid-column: 1/4;
    grid-row: 17/20;
  }
  .ten-thumbnail {
    grid-column: 4/7;
    grid-row: 17/20;
  }

  //CARD ONE
  #grid-wrapper-fiction-mariabodil > div.one-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-fiction-mariabodil > div.two-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-mariabodil > div.three-mariabodil > div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-fiction-mariabodil > div.four-mariabodil > a > div {
    background-size: cover;
    background-position: 25%;
  }
  /// CARD ONE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.one-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD TWO-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.two-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD THREE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.three-thumbnail > a > div {
    background-size: cover;
    background-position: center 15%;
    background-repeat: no-repeat;
  }
  /// CARD FOUR-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.four-thumbnail > a > div {
    background-size: cover;
    background-position: center 40%;
    background-repeat: no-repeat;
  }
  /// CARD FIVE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.five-thumbnail > a > div {
    background-size: cover;
    background-position: center 40%;
    background-repeat: no-repeat;
  }
  /// CARD SIX-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.six-thumbnail > a > div {
    background-size: cover;
    background-position: center 15%;
    background-repeat: no-repeat;
  }
  /// CARD SEVEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.seven-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD EIGHT-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.eight-thumbnail > a > div {
    background-size: cover;
    background-position: center 30%;
    background-repeat: no-repeat;
  }
  /// CARD NINE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.nine-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD TEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.ten-thumbnail > a > div {
    background-size: cover;
    background-position: center 30%;
    background-repeat: no-repeat;
  }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
  #grid-wrapper-fiction-mariabodil {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(19, 200px);
  }
  /// 2 BIG
  .three-mariabodil {
    grid-column: 1/4;
    grid-row: 2/5;
  }
  .one-mariabodil {
    grid-column: 4/7;
    grid-row: 2/5;
  }
  /// 3 SMALL
  .two-mariabodil {
    grid-column: 1/3;
    grid-row: 5/8;
  }
  .four-mariabodil {
    grid-column: 3/5;
    grid-row: 5/8;
  }
  .one-thumbnail {
    grid-column: 5/7;
    grid-row: 5/8;
  }
  /// 2 BIG
  .two-thumbnail {
    grid-column: 1/4;
    grid-row: 8/11;
  }
  .three-thumbnail {
    grid-column: 4/7;
    grid-row: 8/11;
  }
  /// 3 SMALL
  .four-thumbnail {
    grid-column: 1/3;
    grid-row: 11/14;
  }
  .five-thumbnail {
    grid-column: 3/5;
    grid-row: 11/14;
  }
  .six-thumbnail {
    grid-column: 5/7;
    grid-row: 11/14;
  }
  /// 2 BIG
  .seven-thumbnail {
    grid-column: 1/4;
    grid-row: 14/17;
  }
  .eight-thumbnail {
    grid-column: 4/7;
    grid-row: 14/17;
  }
  /// 2 BIG
  .nine-thumbnail {
    grid-column: 1/4;
    grid-row: 17/20;
  }
  .ten-thumbnail {
    grid-column: 4/7;
    grid-row: 17/20;
  }

  //CARD ONE
  #grid-wrapper-fiction-mariabodil > div.one-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-fiction-mariabodil > div.two-mariabodil > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-mariabodil > div.three-mariabodil > div {
    background-size: auto 145%;
    background-position: center 30%;
  }
  //CARD FOUR
  #grid-wrapper-fiction-mariabodil > div.four-mariabodil > a > div {
    background-size: cover;
    background-position: 25%;
  }
  /// CARD ONE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.one-thumbnail > a > div {
    background-size: cover;
    background-position: center 10%;
    background-repeat: no-repeat;
  }
  /// CARD TWO-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.two-thumbnail > a > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /// CARD THREE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.three-thumbnail > a > div {
    background-size: cover;
    background-position: center 10%;
    background-repeat: no-repeat;
  }
  /// CARD FOUR-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.four-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD FIVE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.five-thumbnail > a > div {
    background-size: cover;
    background-position: center 35%;
    background-repeat: no-repeat;
  }
  /// CARD SIX-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.six-thumbnail > a > div {
    background-size: cover;
    background-position: center 10%;
    background-repeat: no-repeat;
  }
  /// CARD SEVEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.seven-thumbnail > a > div {
    background-size: cover;
    background-position: center 10%;
    background-repeat: no-repeat;
  }
  /// CARD EIGHT-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.eight-thumbnail > a > div {
    background-size: cover;
    background-position: center 20%;
    background-repeat: no-repeat;
  }
  /// CARD NINE-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.nine-thumbnail > a > div {
    background-size: cover;
    background-position: center 40%;
    background-repeat: no-repeat;
  }
  /// CARD TEN-THUMBNAIL
  #grid-wrapper-fiction-mariabodil > div.ten-thumbnail > a > div {
    background-size: cover;
    background-position: center 25%;
    background-repeat: no-repeat;
  }
}
