///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-damien {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 90vh);
  }
  .four-damien {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .three-damien {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .one-damien {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .two-damien {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  ///CARD ONE
  #grid-wrapper-fiction-damien > div.one-damien > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-damien > div.two-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-damien > div.three-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-damien > div.four-damien > a div {
    background-size: cover;
    background-position: center;
  }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
  #grid-wrapper-fiction-damien {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 200px);
  }
  /// 2 BIG
  .four-damien {
    grid-column: 1/4;
    grid-row: 1/3;
  }
  .three-damien {
    grid-column: 4/7;
    grid-row: 1/3;
  }
  /// 2 BIG
  .one-damien {
    grid-column: 1/4;
    grid-row: 3/5;
  }
  .two-damien {
    grid-column: 4/7;
    grid-row: 3/5;
  }
  ///CARD ONE
  #grid-wrapper-fiction-damien > div.one-damien > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-damien > div.two-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-damien > div.three-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-damien > div.four-damien > a div {
    background-size: cover;
    background-position: center;
  }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-damien {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 200px);
  }
  /// 2 BIG
  .four-damien {
    grid-column: 1/4;
    grid-row: 2/4;
  }
  .three-damien {
    grid-column: 4/7;
    grid-row: 2/4;
  }
  /// 2 BIG
  .one-damien {
    grid-column: 1/4;
    grid-row: 4/6;
  }
  .two-damien {
    grid-column: 4/7;
    grid-row: 4/6;
  }
  ///CARD ONE
  #grid-wrapper-fiction-damien > div.one-damien > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-damien > div.two-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-damien > div.three-damien > a div {
    background-size: auto;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-damien > div.four-damien > a div {
    background-size: cover;
    background-position: center;
  }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
  #grid-wrapper-fiction-damien {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 200px);
  }
  /// 2 BIG
  .four-damien {
    grid-column: 1/4;
    grid-row: 2/4;
  }
  .three-damien {
    grid-column: 4/7;
    grid-row: 2/4;
  }
  /// 2 BIG
  .one-damien {
    grid-column: 1/4;
    grid-row: 4/6;
  }
  .two-damien {
    grid-column: 4/7;
    grid-row: 4/6;
  }
  ///CARD ONE
  #grid-wrapper-fiction-damien > div.one-damien > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-damien > div.two-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-damien > div.three-damien > a div {
    background-size: auto;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-damien > div.four-damien > a div {
    background-size: cover;
    background-position: center;
  }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
  #grid-wrapper-fiction-damien {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 200px);
  }
  /// 2 BIG
  .four-damien {
    grid-column: 1/4;
    grid-row: 2/5;
  }
  .three-damien {
    grid-column: 4/7;
    grid-row: 2/5;
  }
  /// 2 BIG
  .one-damien {
    grid-column: 1/4;
    grid-row: 5/8;
  }
  .two-damien {
    grid-column: 4/7;
    grid-row: 5/8;
  }
  ///CARD ONE
  #grid-wrapper-fiction-damien > div.one-damien > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-damien > div.two-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-damien > div.three-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-damien > div.four-damien > a div {
    background-size: auto 105%;
    background-position: center;
  }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
  #grid-wrapper-fiction-damien {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 200px);
  }
  /// 2 BIG
  .four-damien {
    grid-column: 1/4;
    grid-row: 2/5;
  }
  .three-damien {
    grid-column: 4/7;
    grid-row: 2/5;
  }
  /// 2 BIG
  .one-damien {
    grid-column: 1/4;
    grid-row: 5/8;
  }
  .two-damien {
    grid-column: 4/7;
    grid-row: 5/8;
  }
  ///CARD ONE
  #grid-wrapper-fiction-damien > div.one-damien > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-damien > div.two-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-damien > div.three-damien > a div {
    background-size: auto 135%;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-damien > div.four-damien > a div {
    background-size: auto 125%;
    background-position: center;
  }
}
