///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-joosje {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 90vh);
  }
  .one-joosje {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .two-joosje {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  ///CARD ONE
  #grid-wrapper-fiction-joosje > div.one-joosje > a div {
    background-size: auto 130%;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-joosje > div.two-joosje > a div {
    background-size: auto 130%;
    background-position: 50%;
  }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
  #grid-wrapper-fiction-joosje {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 200px);
  }
  /// 2 BIG
  .one-joosje {
    grid-column: 1/4;
    grid-row: 1/3;
  }
  .two-joosje {
    grid-column: 4/7;
    grid-row: 1/3;
  }
  //CARD TWO
  #grid-wrapper-fiction-joosje > div.two-joosje > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-fiction-joosje > div.one-joosje > a div {
    background-size: auto 130%;
    background-position: center;
  }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-joosje {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 200px);
  }
  /// 2 BIG
  .one-joosje {
    grid-column: 1/4;
    grid-row: 2/4;
  }
  .two-joosje {
    grid-column: 4/7;
    grid-row: 2/4;
  }
  //CARD TWO
  #grid-wrapper-fiction-joosje > div.two-joosje > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-fiction-joosje > div.one-joosje > a div {
    background-size: auto 130%;
    background-position: center;
  }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
  #grid-wrapper-fiction-joosje {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 200px);
  }
  /// 2 BIG
  .one-joosje {
    grid-column: 1/4;
    grid-row: 2/4;
  }
  .two-joosje {
    grid-column: 4/7;
    grid-row: 2/4;
  }
  //CARD TWO
  #grid-wrapper-fiction-joosje > div.two-joosje > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-fiction-joosje > div.one-joosje > a div {
    background-size: auto 130%;
    background-position: center;
  }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
  #grid-wrapper-fiction-joosje {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 200px);
  }
  /// 2 BIG
  .one-joosje {
    grid-column: 1/4;
    grid-row: 2/5;
  }
  .two-joosje {
    grid-column: 4/7;
    grid-row: 2/5;
  }
  //CARD TWO
  #grid-wrapper-fiction-joosje > div.two-joosje > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-fiction-joosje > div.one-joosje > a div {
    background-size: auto 120%;
    background-position: center;
  }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
  #grid-wrapper-fiction-joosje {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 200px);
  }
  /// 2 BIG
  .one-joosje {
    grid-column: 1/4;
    grid-row: 2/5;
  }
  .two-joosje {
    grid-column: 4/7;
    grid-row: 2/5;
  }
  //CARD TWO
  #grid-wrapper-fiction-joosje > div.two-joosje > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-fiction-joosje > div.one-joosje > a div {
    background-size: auto 120%;
    background-position: center;
  }
}
