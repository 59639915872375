///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
  #grid-wrapper-featured {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(21, 90vh);
  }
  .two {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .three {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .four {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .six {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  .one {
    grid-column: 1/2;
    grid-row: 5/6;
  }
  .five {
    grid-column: 1/2;
    grid-row: 6/7;
  }
  .twenty {
    grid-column: 1/2;
    grid-row: 7/8;
  }
  .eight {
    grid-column: 1/2;
    grid-row: 8/9;
  }
  .seven {
    grid-column: 1/2;
    grid-row: 9/10;
  }
  .eleven {
    grid-column: 1/2;
    grid-row: 10/11;
  }
  .nine {
    grid-column: 1/2;
    grid-row: 11/12;
  }
  .ten {
    grid-column: 1/2;
    grid-row: 12/13;
  }
  .twelve {
    grid-column: 1/2;
    grid-row: 13/14;
  }
  .fourteen {
    grid-column: 1/2;
    grid-row: 14/15;
  }
  .fifteen {
    grid-column: 1/2;
    grid-row: 15/16;
  }
  .thirteen {
    grid-column: 1/2;
    grid-row: 16/17;
  }
  .seventeen {
    grid-column: 1/2;
    grid-row: 17/18;
  }
  .sixteen {
    grid-column: 1/2;
    grid-row: 18/19;
  }
  .nineteen {
    grid-column: 1/2;
    grid-row: 19/20;
  }
  .eighteen {
    grid-column: 1/2;
    grid-row: 20/21;
  }
  .twenty-one {
    grid-column: 1/2;
    grid-row: 21/22;
  }

  //CARD TWENTY-ONE
  #grid-wrapper-featured > div.twenty-one > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWENTY
  #grid-wrapper-featured > div.twenty > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD NINETEEN
  #grid-wrapper-featured > div.nineteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHTEEN
  #grid-wrapper-featured > div.eighteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD SEVENTEEN
  #grid-wrapper-featured > div.seventeen > a div {
    background-size: auto 115%;
    background-position: center;
  }
  //CARD SIXTEEN
  #grid-wrapper-featured > div.sixteen > a div {
    background-size: auto 110%;
    background-position: center;
  }
  //CARD FIFTEEN
  #grid-wrapper-featured > div.fifteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOURTEEN
  #grid-wrapper-featured > div.fourteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THIRTEEN
  #grid-wrapper-featured > div.thirteen > a div {
    background-size: cover;
    background-position: 30%;
  }
  //CARD TWELVE
  #grid-wrapper-featured > div.twelve > a div {
    background-size: auto 125%;
    background-position: 35%;
  }
  //CARD ELEVEN
  #grid-wrapper-featured > div.eleven > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TEN
  #grid-wrapper-featured > div.ten > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD NINE
  #grid-wrapper-featured > div.nine > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHT
  #grid-wrapper-featured > div.eight > a div {
    background-size: auto 135%;
    background-position: 40%;
  }
  //CARD SEVEN
  #grid-wrapper-featured > div.seven > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIX
  #grid-wrapper-featured > div.six > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FIVE
  #grid-wrapper-featured > div.five > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-featured > div.four > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-featured > div.three > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-featured > div.two > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-featured > div.one > a div {
    background-size: cover;
    background-position: 75%;
  }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
  #grid-wrapper-featured {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(16, 200px);
  }
  /// 3 SMALL
  .two {
    grid-column: 1/3;
    grid-row: 1/3;
  }
  .three {
    grid-column: 3/5;
    grid-row: 1/3;
  }
  .four {
    grid-column: 5/7;
    grid-row: 1/3;
  }

  /// 3 SMALL
  .six {
    grid-column: 1/3;
    grid-row: 3/5;
  }
  .one {
    grid-column: 3/5;
    grid-row: 3/5;
  }
  .five {
    grid-column: 5/7;
    grid-row: 3/5;
  }

  /// 2 BIG
  .twenty {
    grid-column: 1/4;
    grid-row: 5/7;
  }
  .eight {
    grid-column: 4/7;
    grid-row: 5/7;
  }

  /// 3 SMALL
  .seven {
    grid-column: 1/3;
    grid-row: 7/9;
  }
  .eleven {
    grid-column: 3/5;
    grid-row: 7/9;
  }
  .nine {
    grid-column: 5/7;
    grid-row: 7/9;
  }

  /// 2 Big
  .ten {
    grid-column: 1/4;
    grid-row: 9/11;
  }
  .twelve {
    grid-column: 4/7;
    grid-row: 9/11;
  }

  /// 3 SMALL
  .fourteen {
    grid-column: 1/3;
    grid-row: 11/13;
  }
  .fifteen {
    grid-column: 3/5;
    grid-row: 11/13;
  }
  .thirteen {
    grid-column: 5/7;
    grid-row: 11/13;
  }

  /// 2 BIG
  .seventeen {
    grid-column: 1/4;
    grid-row: 13/15;
  }
  .sixteen {
    grid-column: 4/7;
    grid-row: 13/15;
  }

  /// 3 SMALL
  .nineteen {
    grid-column: 1/3;
    grid-row: 15/17;
  }
  .eighteen {
    grid-column: 3/5;
    grid-row: 15/17;
  }
  .twenty-one {
    grid-column: 5/7;
    grid-row: 15/17;
  }

  //CARD TWENTY-ONE
  #grid-wrapper-featured > div.twenty-one > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWENTY
  #grid-wrapper-featured > div.twenty > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD NINETEEN
  #grid-wrapper-featured > div.nineteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHTEEN
  #grid-wrapper-featured > div.eighteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD SEVENTEEN
  #grid-wrapper-featured > div.seventeen > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIXTEEN
  #grid-wrapper-featured > div.sixteen > a div {
    background-size: auto 115%;
    background-position: center;
  }
  //CARD FIFTEEN
  #grid-wrapper-featured > div.fifteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOURTEEN
  #grid-wrapper-featured > div.fourteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THIRTEEN
  #grid-wrapper-featured > div.thirteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWELVE
  #grid-wrapper-featured > div.twelve > a div {
    background-size: auto 125%;
    background-position: 35%;
  }
  //CARD ELEVEN
  #grid-wrapper-featured > div.eleven > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TEN
  #grid-wrapper-featured > div.ten > a div {
    background-size: auto 130%;
    background-position: center;
  }
  //CARD NINE
  #grid-wrapper-featured > div.nine > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHT
  #grid-wrapper-featured > div.eight > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD SEVEN
  #grid-wrapper-featured > div.seven > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIX
  #grid-wrapper-featured > div.six > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FIVE
  #grid-wrapper-featured > div.five > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-featured > div.four > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-featured > div.three > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-featured > div.two > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-featured > div.one > a div {
    background-size: cover;
    background-position: 80%;
  }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
  #grid-wrapper-featured {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(17, 200px);
  }
  /// 3 SMALL
  .two {
    grid-column: 1/3;
    grid-row: 2/4;
  }
  .three {
    grid-column: 3/5;
    grid-row: 2/4;
  }
  .four {
    grid-column: 5/7;
    grid-row: 2/4;
  }

  /// 3 SMALL
  .six {
    grid-column: 1/3;
    grid-row: 4/6;
  }
  .one {
    grid-column: 3/5;
    grid-row: 4/6;
  }
  .five {
    grid-column: 5/7;
    grid-row: 4/6;
  }

  /// 2 BIG
  .twenty {
    grid-column: 1/4;
    grid-row: 6/8;
  }
  .eight {
    grid-column: 4/7;
    grid-row: 6/8;
  }

  /// 3 SMALL
  .seven {
    grid-column: 1/3;
    grid-row: 8/10;
  }
  .eleven {
    grid-column: 3/5;
    grid-row: 8/10;
  }
  .nine {
    grid-column: 5/7;
    grid-row: 8/10;
  }

  /// 2 Big
  .ten {
    grid-column: 1/4;
    grid-row: 10/12;
  }
  .twelve {
    grid-column: 4/7;
    grid-row: 10/12;
  }

  /// 3 SMALL
  .fourteen {
    grid-column: 1/3;
    grid-row: 12/14;
  }
  .fifteen {
    grid-column: 3/5;
    grid-row: 12/14;
  }
  .thirteen {
    grid-column: 5/7;
    grid-row: 12/14;
  }

  /// 2 BIG
  .seventeen {
    grid-column: 1/4;
    grid-row: 14/16;
  }
  .sixteen {
    grid-column: 4/7;
    grid-row: 14/16;
  }

  /// 3 SMALL
  .nineteen {
    grid-column: 1/3;
    grid-row: 16/18;
  }
  .eighteen {
    grid-column: 3/5;
    grid-row: 16/18;
  }
  .twenty-one {
    grid-column: 5/7;
    grid-row: 16/18;
  }

  //CARD TWENTY-ONE
  #grid-wrapper-featured > div.twenty-one > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWENTY
  #grid-wrapper-featured > div.twenty > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD NINETEEN
  #grid-wrapper-featured > div.nineteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHTEEN
  #grid-wrapper-featured > div.eighteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD SEVENTEEN
  #grid-wrapper-featured > div.seventeen > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIXTEEN
  #grid-wrapper-featured > div.sixteen > a div {
    background-size: auto 115%;
    background-position: center;
  }
  //CARD FIFTEEN
  #grid-wrapper-featured > div.fifteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOURTEEN
  #grid-wrapper-featured > div.fourteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THIRTEEN
  #grid-wrapper-featured > div.thirteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWELVE
  #grid-wrapper-featured > div.twelve > a div {
    background-size: auto 125%;
    background-position: 35%;
  }
  //CARD ELEVEN
  #grid-wrapper-featured > div.eleven > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TEN
  #grid-wrapper-featured > div.ten > a div {
    background-size: auto 130%;
    background-position: center;
  }
  //CARD NINE
  #grid-wrapper-featured > div.nine > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHT
  #grid-wrapper-featured > div.eight > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD SEVEN
  #grid-wrapper-featured > div.seven > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIX
  #grid-wrapper-featured > div.six > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FIVE
  #grid-wrapper-featured > div.five > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-featured > div.four > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-featured > div.three > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-featured > div.two > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-featured > div.one > a div {
    background-size: cover;
    background-position: 80%;
  }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
  #grid-wrapper-featured {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(17, 200px);
  }
  /// 3 SMALL
  .two {
    grid-column: 1/3;
    grid-row: 2/4;
  }
  .three {
    grid-column: 3/5;
    grid-row: 2/4;
  }
  .four {
    grid-column: 5/7;
    grid-row: 2/4;
  }

  /// 3 SMALL
  .six {
    grid-column: 1/3;
    grid-row: 4/6;
  }
  .one {
    grid-column: 3/5;
    grid-row: 4/6;
  }
  .five {
    grid-column: 5/7;
    grid-row: 4/6;
  }

  /// 2 BIG
  .twenty {
    grid-column: 1/4;
    grid-row: 6/8;
  }
  .eight {
    grid-column: 4/7;
    grid-row: 6/8;
  }

  /// 3 SMALL
  .seven {
    grid-column: 1/3;
    grid-row: 8/10;
  }
  .eleven {
    grid-column: 3/5;
    grid-row: 8/10;
  }
  .nine {
    grid-column: 5/7;
    grid-row: 8/10;
  }

  /// 2 Big
  .ten {
    grid-column: 1/4;
    grid-row: 10/12;
  }
  .twelve {
    grid-column: 4/7;
    grid-row: 10/12;
  }

  /// 3 SMALL
  .fourteen {
    grid-column: 1/3;
    grid-row: 12/14;
  }
  .fifteen {
    grid-column: 3/5;
    grid-row: 12/14;
  }
  .thirteen {
    grid-column: 5/7;
    grid-row: 12/14;
  }

  /// 2 BIG
  .seventeen {
    grid-column: 1/4;
    grid-row: 14/16;
  }
  .sixteen {
    grid-column: 4/7;
    grid-row: 14/16;
  }

  /// 3 SMALL
  .nineteen {
    grid-column: 1/3;
    grid-row: 16/18;
  }
  .eighteen {
    grid-column: 3/5;
    grid-row: 16/18;
  }
  .twenty-one {
    grid-column: 5/7;
    grid-row: 16/18;
  }

  //CARD TWENTY-ONE
  #grid-wrapper-featured > div.twenty-one > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWENTY
  #grid-wrapper-featured > div.twenty > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD NINETEEN
  #grid-wrapper-featured > div.nineteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHTEEN
  #grid-wrapper-featured > div.eighteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD SEVENTEEN
  #grid-wrapper-featured > div.seventeen > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIXTEEN
  #grid-wrapper-featured > div.sixteen > a div {
    background-size: auto 115%;
    background-position: center;
  }
  //CARD FIFTEEN
  #grid-wrapper-featured > div.fifteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOURTEEN
  #grid-wrapper-featured > div.fourteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THIRTEEN
  #grid-wrapper-featured > div.thirteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWELVE
  #grid-wrapper-featured > div.twelve > a div {
    background-size: auto 125%;
    background-position: 35%;
  }
  //CARD ELEVEN
  #grid-wrapper-featured > div.eleven > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TEN
  #grid-wrapper-featured > div.ten > a div {
    background-size: auto 130%;
    background-position: center;
  }
  //CARD NINE
  #grid-wrapper-featured > div.nine > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHT
  #grid-wrapper-featured > div.eight > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD SEVEN
  #grid-wrapper-featured > div.seven > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIX
  #grid-wrapper-featured > div.six > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FIVE
  #grid-wrapper-featured > div.five > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-featured > div.four > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-featured > div.three > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-featured > div.two > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-featured > div.one > a div {
    background-size: cover;
    background-position: 80%;
  }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
  #grid-wrapper-featured {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(25, 200px);
  }
  /// 3 SMALL
  .two {
    grid-column: 1/3;
    grid-row: 2/5;
  }
  .three {
    grid-column: 3/5;
    grid-row: 2/5;
  }
  .four {
    grid-column: 5/7;
    grid-row: 2/5;
  }

  /// 3 SMALL
  .six {
    grid-column: 1/3;
    grid-row: 5/8;
  }
  .one {
    grid-column: 3/5;
    grid-row: 5/8;
  }
  .five {
    grid-column: 5/7;
    grid-row: 5/8;
  }

  /// 2 BIG
  .twenty {
    grid-column: 1/4;
    grid-row: 8/11;
  }
  .eight {
    grid-column: 4/7;
    grid-row: 8/11;
  }

  /// 3 SMALL
  .seven {
    grid-column: 1/3;
    grid-row: 11/14;
  }
  .eleven {
    grid-column: 3/5;
    grid-row: 11/14;
  }
  .nine {
    grid-column: 5/7;
    grid-row: 11/14;
  }

  /// 2 Big
  .ten {
    grid-column: 1/4;
    grid-row: 14/17;
  }
  .twelve {
    grid-column: 4/7;
    grid-row: 14/17;
  }

  /// 3 SMALL
  .fourteen {
    grid-column: 1/3;
    grid-row: 17/20;
  }
  .fifteen {
    grid-column: 3/5;
    grid-row: 17/20;
  }
  .thirteen {
    grid-column: 5/7;
    grid-row: 17/20;
  }

  /// 2 BIG
  .seventeen {
    grid-column: 1/4;
    grid-row: 20/23;
  }
  .sixteen {
    grid-column: 4/7;
    grid-row: 20/23;
  }

  /// 3 SMALL
  .nineteen {
    grid-column: 1/3;
    grid-row: 23/26;
  }
  .eighteen {
    grid-column: 3/5;
    grid-row: 23/26;
  }
  .twenty-one {
    grid-column: 5/7;
    grid-row: 23/26;
  }

  //CARD TWENTY-ONE
  #grid-wrapper-featured > div.twenty-one > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWENTY
  #grid-wrapper-featured > div.twenty > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD NINETEEN
  #grid-wrapper-featured > div.nineteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHTEEN
  #grid-wrapper-featured > div.eighteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD SEVENTEEN
  #grid-wrapper-featured > div.seventeen > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIXTEEN
  #grid-wrapper-featured > div.sixteen > a div {
    background-size: auto 115%;
    background-position: center;
  }
  //CARD FIFTEEN
  #grid-wrapper-featured > div.fifteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOURTEEN
  #grid-wrapper-featured > div.fourteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THIRTEEN
  #grid-wrapper-featured > div.thirteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWELVE
  #grid-wrapper-featured > div.twelve > a div {
    background-size: auto 125%;
    background-position: 35%;
  }
  //CARD ELEVEN
  #grid-wrapper-featured > div.eleven > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TEN
  #grid-wrapper-featured > div.ten > a div {
    background-size: auto 130%;
    background-position: center;
  }
  //CARD NINE
  #grid-wrapper-featured > div.nine > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHT
  #grid-wrapper-featured > div.eight > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD SEVEN
  #grid-wrapper-featured > div.seven > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIX
  #grid-wrapper-featured > div.six > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FIVE
  #grid-wrapper-featured > div.five > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-featured > div.four > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-featured > div.three > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-featured > div.two > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-featured > div.one > a div {
    background-size: cover;
    background-position: 80%;
  }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
  #grid-wrapper-featured {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(25, 200px);
  }
  /// 3 SMALL
  .two {
    grid-column: 1/3;
    grid-row: 2/5;
  }
  .three {
    grid-column: 3/5;
    grid-row: 2/5;
  }
  .four {
    grid-column: 5/7;
    grid-row: 2/5;
  }

  /// 3 SMALL
  .six {
    grid-column: 1/3;
    grid-row: 5/8;
  }
  .one {
    grid-column: 3/5;
    grid-row: 5/8;
  }
  .five {
    grid-column: 5/7;
    grid-row: 5/8;
  }

  /// 2 BIG
  .twenty {
    grid-column: 1/4;
    grid-row: 8/11;
  }
  .eight {
    grid-column: 4/7;
    grid-row: 8/11;
  }

  /// 3 SMALL
  .seven {
    grid-column: 1/3;
    grid-row: 11/14;
  }
  .eleven {
    grid-column: 3/5;
    grid-row: 11/14;
  }
  .nine {
    grid-column: 5/7;
    grid-row: 11/14;
  }

  /// 2 Big
  .ten {
    grid-column: 1/4;
    grid-row: 14/17;
  }
  .twelve {
    grid-column: 4/7;
    grid-row: 14/17;
  }

  /// 3 SMALL
  .fourteen {
    grid-column: 1/3;
    grid-row: 17/20;
  }
  .fifteen {
    grid-column: 3/5;
    grid-row: 17/20;
  }
  .thirteen {
    grid-column: 5/7;
    grid-row: 17/20;
  }

  /// 2 BIG
  .seventeen {
    grid-column: 1/4;
    grid-row: 20/23;
  }
  .sixteen {
    grid-column: 4/7;
    grid-row: 20/23;
  }

  /// 3 SMALL
  .nineteen {
    grid-column: 1/3;
    grid-row: 23/26;
  }
  .eighteen {
    grid-column: 3/5;
    grid-row: 23/26;
  }
  .twenty-one {
    grid-column: 5/7;
    grid-row: 23/26;
  }

  //CARD TWENTY-ONE
  #grid-wrapper-featured > div.twenty-one > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWENTY
  #grid-wrapper-featured > div.twenty > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD NINETEEN
  #grid-wrapper-featured > div.nineteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHTEEN
  #grid-wrapper-featured > div.eighteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD SEVENTEEN
  #grid-wrapper-featured > div.seventeen > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIXTEEN
  #grid-wrapper-featured > div.sixteen > a div {
    background-size: auto 115%;
    background-position: center;
  }
  //CARD FIFTEEN
  #grid-wrapper-featured > div.fifteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOURTEEN
  #grid-wrapper-featured > div.fourteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THIRTEEN
  #grid-wrapper-featured > div.thirteen > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWELVE
  #grid-wrapper-featured > div.twelve > a div {
    background-size: auto 125%;
    background-position: 35%;
  }
  //CARD ELEVEN
  #grid-wrapper-featured > div.eleven > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TEN
  #grid-wrapper-featured > div.ten > a div {
    background-size: auto 130%;
    background-position: center;
  }
  //CARD NINE
  #grid-wrapper-featured > div.nine > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD EIGHT
  #grid-wrapper-featured > div.eight > a div {
    background-size: auto 135%;
    background-position: center;
  }
  //CARD SEVEN
  #grid-wrapper-featured > div.seven > a div {
    background-size: auto 120%;
    background-position: center;
  }
  //CARD SIX
  #grid-wrapper-featured > div.six > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FIVE
  #grid-wrapper-featured > div.five > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD FOUR
  #grid-wrapper-featured > div.four > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-featured > div.three > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD TWO
  #grid-wrapper-featured > div.two > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD ONE
  #grid-wrapper-featured > div.one > a div {
    background-size: cover;
    background-position: 80%;
  }
}
