///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-bram {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 90vh);
  }
  .three-bram {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .two-bram {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .one-bram {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  ///CARD ONE
  #grid-wrapper-fiction-bram > div.one-bram > a div {
    background-size: cover;
    background-position: 65%;
  }
  ///CARD TWO
  #grid-wrapper-fiction-bram > div.two-bram > a div {
    background-size: cover;
    background-position: center;
  }
  //CARD THREE
  #grid-wrapper-fiction-bram > div.three-bram > a div {
    background-size: cover;
    background-position: 95%;
  }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
  #grid-wrapper-fiction-bram {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 200px);
  }
  /// 3 SMALL
  .three-bram {
    grid-column: 1/3;
    grid-row: 1/3;
  }
  .two-bram {
    grid-column: 3/5;
    grid-row: 1/3;
  }
  .one-bram {
    grid-column: 5/7;
    grid-row: 1/3;
  }
  ///CARD ONE
  #grid-wrapper-fiction-bram > div.one-bram > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-bram > div.two-bram > a div {
    background-size: cover;
    background-position: 85%;
  }
  ///CARD THREE
  #grid-wrapper-fiction-bram > div.three-bram > a div {
    background-size: cover;
    background-position: 70%;
  }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-bram {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 200px);
  }
  /// 3 SMALL
  .three-bram {
    grid-column: 1/3;
    grid-row: 2/4;
  }
  .two-bram {
    grid-column: 3/5;
    grid-row: 2/4;
  }
  .one-bram {
    grid-column: 5/7;
    grid-row: 2/4;
  }
  ///CARD ONE
  #grid-wrapper-fiction-bram > div.one-bram > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-bram > div.two-bram > a div {
    background-size: cover;
    background-position: 85%;
  }
  ///CARD THREE
  #grid-wrapper-fiction-bram > div.three-bram > a div {
    background-size: cover;
    background-position: 55%;
  }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
  #grid-wrapper-fiction-bram {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 200px);
  }
  /// 3 SMALL
  .three-bram {
    grid-column: 1/3;
    grid-row: 2/4;
  }
  .two-bram {
    grid-column: 3/5;
    grid-row: 2/4;
  }
  .one-bram {
    grid-column: 5/7;
    grid-row: 2/4;
  }
  ///CARD ONE
  #grid-wrapper-fiction-bram > div.one-bram > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-bram > div.two-bram > a div {
    background-size: cover;
    background-position: 85%;
  }
  ///CARD THREE
  #grid-wrapper-fiction-bram > div.three-bram > a div {
    background-size: cover;
    background-position: 70%;
  }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
  #grid-wrapper-fiction-bram {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 200px);
  }
  /// 3 SMALL
  .three-bram {
    grid-column: 1/3;
    grid-row: 2/5;
  }
  .two-bram {
    grid-column: 3/5;
    grid-row: 2/5;
  }
  .one-bram {
    grid-column: 5/7;
    grid-row: 2/5;
  }
  ///CARD ONE
  #grid-wrapper-fiction-bram > div.one-bram > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-bram > div.two-bram > a div {
    background-size: cover;
    background-position: 85%;
  }
  ///CARD THREE
  #grid-wrapper-fiction-bram > div.three-bram > a div {
    background-size: cover;
    background-position: 70%;
  }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
  #grid-wrapper-fiction-bram {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 200px);
  }
  /// 3 SMALL
  .three-bram {
    grid-column: 1/3;
    grid-row: 2/5;
  }
  .two-bram {
    grid-column: 3/5;
    grid-row: 2/5;
  }
  .one-bram {
    grid-column: 5/7;
    grid-row: 2/5;
  }
  ///CARD ONE
  #grid-wrapper-fiction-bram > div.one-bram > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-bram > div.two-bram > a div {
    background-size: cover;
    background-position: 85%;
  }
  ///CARD THREE
  #grid-wrapper-fiction-bram > div.three-bram > a div {
    background-size: cover;
    background-position: 70%;
  }
}
