.awards-container {
  position: absolute;
  display: flex;
  .awards {
    h3 {
      opacity: 0.9;
    }
    i {
      font-size: 10px;
      color: #f5a1a3;
    }
  }
}
///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
  .awards-container {
    flex-direction: column;
    margin: 10em auto;
    padding: 0 8em;
  }
  .awards-container .awards {
    h1 {
      letter-spacing: 0.1rem;
      font-size: 15px;
      margin-bottom: 5px;
    }
    h3 {
      letter-spacing: 0.06rem;
      line-height: 12px;
      font-size: 13px;
    }
  }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
  .awards-container {
    justify-content: center;
    flex-wrap: nowrap;
    margin: 6em auto;
    padding: 0 7em;
  }
  .awards-container .awards {
    h1 {
      letter-spacing: 0.05rem;
      font-size: 14px;
      margin-bottom: 5px;
    }
    h3 {
      letter-spacing: 0.06rem;
      line-height: 12px;
      font-size: 12.5px;
    }
  }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
  .awards-container {
    justify-content: center;
    flex-wrap: nowrap;
    margin: 15em auto;
    padding: 0 3em;
  }
  .awards-container .awards {
    h1 {
      letter-spacing: 0.05rem;
      font-size: 17px;
      margin-bottom: 10px;
    }
    h3 {
      letter-spacing: 0.04rem;
      line-height: 1rem;
      font-size: 15px;
    }
  }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
  .awards-container {
    justify-content: center;
    flex-wrap: nowrap;
    margin: 10em auto;
    padding: 0 5em;
  }
  .awards-container .awards {
    h1 {
      letter-spacing: 0.05rem;
      font-size: 18px;
      margin-bottom: 10px;
    }
    h3 {
      letter-spacing: 0.05rem;
      line-height: 1.1rem;
      font-size: 16px;
    }
  }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
  .awards-container {
    justify-content: center;
    flex-wrap: nowrap;
    margin: 10em auto;
    padding: 0 8em;
  }
  .awards-container .awards {
    h1 {
      letter-spacing: 0.05rem;
      font-size: 20px;
      margin-bottom: 10px;
    }
    h3 {
      letter-spacing: 0.05rem;
      line-height: 0.89rem;
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
  .awards-container {
    justify-content: center;
    flex-wrap: nowrap;
    margin: 12em auto;
    padding: 0 14em;
  }
  .awards-container .awards {
    h1 {
      letter-spacing: 0.05rem;
      font-size: 21px;
      margin-bottom: 10px;
    }
    h3 {
      letter-spacing: 0.05rem;
      line-height: 0.89rem;
      font-size: 19px;
    }
  }
}
