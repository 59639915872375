///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-simon {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 90vh);
  }
  .one-simon {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .four-simon {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .two-simon {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .three-simon {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  ///CARD ONE
  #grid-wrapper-fiction-simon > div.one-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-simon > div.two-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD THREE
  #grid-wrapper-fiction-simon > div.three-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-simon > div.four-simon > a div {
    background-size: cover;
    background-position: center;
  }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
  #grid-wrapper-fiction-simon {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 200px);
  }
  /// 2 BIG
  .one-simon {
    grid-column: 1/4;
    grid-row: 1/3;
  }
  .four-simon {
    grid-column: 4/7;
    grid-row: 1/3;
  }
  /// 2 BIG
  .two-simon {
    grid-column: 1/4;
    grid-row: 3/5;
  }
  .three-simon {
    grid-column: 4/7;
    grid-row: 3/5;
  }
  ///CARD ONE
  #grid-wrapper-fiction-simon > div.one-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-simon > div.two-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD THREE
  #grid-wrapper-fiction-simon > div.three-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-simon > div.four-simon > a div {
    background-size: cover;
    background-position: center;
  }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
  #grid-wrapper-fiction-simon {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 200px);
  }
  /// 2 BIG
  .one-simon {
    grid-column: 1/4;
    grid-row: 2/4;
  }
  .four-simon {
    grid-column: 4/7;
    grid-row: 2/4;
  }
  /// 2 BIG
  .two-simon {
    grid-column: 1/4;
    grid-row: 4/6;
  }
  .three-simon {
    grid-column: 4/7;
    grid-row: 4/6;
  }
  ///CARD ONE
  #grid-wrapper-fiction-simon > div.one-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-simon > div.two-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD THREE
  #grid-wrapper-fiction-simon > div.three-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-simon > div.four-simon > a div {
    background-size: cover;
    background-position: center;
  }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
  #grid-wrapper-fiction-simon {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 200px);
  }
  /// 2 BIG
  .one-simon {
    grid-column: 1/4;
    grid-row: 2/4;
  }
  .four-simon {
    grid-column: 4/7;
    grid-row: 2/4;
  }
  /// 2 BIG
  .two-simon {
    grid-column: 1/4;
    grid-row: 4/6;
  }
  .three-simon {
    grid-column: 4/7;
    grid-row: 4/6;
  }
  ///CARD ONE
  #grid-wrapper-fiction-simon > div.one-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-simon > div.two-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD THREE
  #grid-wrapper-fiction-simon > div.three-simon > a div {
    background-size: cover;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-simon > div.four-simon > a div {
    background-size: cover;
    background-position: center;
  }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
  #grid-wrapper-fiction-simon {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 200px);
  }
  /// 2 BIG
  .one-simon {
    grid-column: 1/4;
    grid-row: 2/5;
  }
  .four-simon {
    grid-column: 4/7;
    grid-row: 2/5;
  }
  /// 2 BIG
  .two-simon {
    grid-column: 1/4;
    grid-row: 5/8;
  }
  .three-simon {
    grid-column: 4/7;
    grid-row: 5/8;
  }
  ///CARD ONE
  #grid-wrapper-fiction-simon > div.one-simon > a div {
    background-size: auto 135%;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-simon > div.two-simon > a div {
    background-size: auto 125%;
    background-position: center;
  }
  ///CARD THREE
  #grid-wrapper-fiction-simon > div.three-simon > a div {
    background-size: auto 130%;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-simon > div.four-simon > a div {
    background-size: cover;
    background-position: center;
  }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
  #grid-wrapper-fiction-simon {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 200px);
  }
  /// 2 BIG
  .one-simon {
    grid-column: 1/4;
    grid-row: 2/5;
  }
  .four-simon {
    grid-column: 4/7;
    grid-row: 2/5;
  }
  /// 2 BIG
  .two-simon {
    grid-column: 1/4;
    grid-row: 5/8;
  }
  .three-simon {
    grid-column: 4/7;
    grid-row: 5/8;
  }
  ///CARD ONE
  #grid-wrapper-fiction-simon > div.one-simon > a div {
    background-size: auto 165%;
    background-position: center;
  }
  ///CARD TWO
  #grid-wrapper-fiction-simon > div.two-simon > a div {
    background-size: auto 140%;
    background-position: center;
  }
  ///CARD THREE
  #grid-wrapper-fiction-simon > div.three-simon > a div {
    background-size: auto 145%;
    background-position: center;
  }
  ///CARD FOUR
  #grid-wrapper-fiction-simon > div.four-simon > a div {
    background-size: cover;
    background-position: center;
  }
}
