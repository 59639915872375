.contact-container .cards {
  h3 {
    margin: 0;
  }
}
///Mobile Portrait
@media screen and (max-width: 600px) and (orientation: portrait) {
  .contact-container {
    max-width: 30em;
    padding: 14vh 5.5em;
  }
  .contact-container .cards {
    max-width: 200px;
    margin: 35px auto;
    h1 {
      letter-spacing: 0.05rem;
      font-size: 14px;
      margin-bottom: 5px;
    }
    h3 {
      letter-spacing: 0.03rem;
      line-height: 17px;
      font-size: 12.5px;
    }
  }
  ///HQ BBKK
  .content-details-phone-five > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///EMILY
  .content-details-phone-two > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///QUINTIN
  .content-details-phone > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///LAUREN
  .content-details-phone-four > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///CREATIVES
  #root > section > div > div.cards.three > h3:nth-child(4) {
    margin-top: 8px;
  }
}
///Mobile Landscape
@media only screen and (min-width: 600px) and (max-width: 926px) and (orientation: landscape) {
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 22vh 19vw;
  }
  .contact-container .cards {
    max-width: 200px;
    margin: 10px 0px 10px 0px;
    padding: 0px 10px 0px 10px;
    h1 {
      letter-spacing: 0.05rem;
      font-size: 14px;
      margin-bottom: 5px;
    }
    h3 {
      letter-spacing: 0.03rem;
      line-height: 17px;
      font-size: 12.5px;
    }
  }
  ///HQ BBKK
  #root > section > div > div.cards.five > a.content-details-phone-five > h3 {
    color: #f5a1a3;
  }
  ///EMILY
  #root > section > div > div.cards.two > a.content-details-phone-two > h3 {
    color: #f5a1a3;
  }
  ///QUINTIN
  #root > section > div > div.cards.one > a.content-details-phone > h3 {
    color: #f5a1a3;
  }
  ///LAUREN
  #root > section > div > div.cards.four > a.content-details-phone-four > h3 {
    color: #f5a1a3;
  }
  ///CREATIVES
  #root > section > div > div.cards.three > h3:nth-child(4) {
    margin-top: 8px;
  }
}
///Tablet Portrait
@media screen and (min-width: 600px) and (orientation: portrait) {
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20vh 6em 25vh 6em;
  }
  .contact-container .cards {
    max-width: 250px;
    margin: 15px auto;
    padding: 0px 10px 0px 10px;
    h1 {
      letter-spacing: 0.05rem;
      font-size: 20px;
      margin-bottom: 10px;
    }
    h3 {
      letter-spacing: 0.05rem;
      line-height: 1.8rem;
      font-size: 18px;
    }
  }
  ///HQ BBKK
  .content-details-phone-five > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///EMILY
  .content-details-phone-two > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///QUINTIN
  .content-details-phone > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///LAUREN
  .content-details-phone-four > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///CREATIVES
  #root > section > div > div.cards.three > h3:nth-child(4) {
    margin-top: 8px;
  }
}
///Tablet Landscape
@media screen and (min-width: 1023px) and (max-width: 1280px) and (orientation: landscape) {
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    padding: 15vh 15em 15vh 15em;
  }
  .contact-container .cards {
    max-width: 250px;
    margin: 15px auto;
    padding: 0px 10px 0px 10px;
    h1 {
      letter-spacing: 0.05rem;
      font-size: 20px;
      margin-bottom: 10px;
    }
    h3 {
      letter-spacing: 0.05rem;
      line-height: 1.8rem;
      font-size: 18px;
    }
  }
  ///HQ BBKK
  .content-details-phone-five > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///EMILY
  .content-details-phone-two > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///QUINTIN
  .content-details-phone > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///LAUREN
  .content-details-phone-four > h3:nth-child(1) {
    color: #f5a1a3;
  }
  ///CREATIVES
  #root > section > div > div.cards.three > h3:nth-child(4) {
    margin-top: 8px;
  }
}
///Desktop
@media screen and (min-width: 1281px) and (max-width: 1960px) {
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10% 25% 10% 25%;
  }
  .contact-container .cards {
    max-width: 300px;
    margin: 15px 0px 15px 50px;
    h1 {
      letter-spacing: 0.05rem;
      font-size: 20px;
      margin-bottom: 10px;
    }
    h3 {
      letter-spacing: 0.05rem;
      line-height: 1.4rem;
      font-size: 18px;
    }
  }
  ///HQ BBKK
  #root > section > div > div.cards.five > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.five > a.content-details-phone-five > h3 {
    color: #f5a1a3;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  ///EMILY
  #root > section > div > div.cards.two > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.two > a.content-details-phone-two > h3 {
    color: #f5a1a3;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  ///QUINTIN
  #root > section > div > div.cards.one > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.one > a.content-details-phone > h3 {
    color: #f5a1a3;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  ///LAUREN
  #root > section > div > div.cards.four > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.four > a.content-details-phone-four > h3 {
    color: #f5a1a3;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  ///CREATIVES
  #root > section > div > div.cards.three > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.three > h3:nth-child(4) {
    margin-top: 8px;
  }
  ///SOCIALS
  #root > section > div > div.cards.six > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
}
///Desktop Big
@media screen and (min-width: 1961px) and (max-width: 2400px) {
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10% 30% 10% 30%;
  }
  .contact-container .cards {
    max-width: 300px;
    margin: 15px 0px 15px 50px;
    h1 {
      letter-spacing: 0.05rem;
      font-size: 21px;
      margin-bottom: 10px;
    }
    h3 {
      letter-spacing: 0.05rem;
      line-height: 1.5rem;
      font-size: 19px;
    }
  }
  ///HQ BBKK
  #root > section > div > div.cards.five > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.five > a.content-details-phone-five > h3 {
    color: #f5a1a3;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  ///EMILY
  #root > section > div > div.cards.two > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.two > a.content-details-phone-two > h3 {
    color: #f5a1a3;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  ///QUINTIN
  #root > section > div > div.cards.one > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.one > a.content-details-phone > h3 {
    color: #f5a1a3;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  ///LAUREN
  #root > section > div > div.cards.four > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.four > a.content-details-phone-four > h3 {
    color: #f5a1a3;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  ///CREATIVES
  #root > section > div > div.cards.three > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
  #root > section > div > div.cards.three > h3:nth-child(4) {
    margin-top: 8px;
  }
  ///SOCIALS
  #root > section > div > div.cards.six > a > h3 {
    opacity: 0.75;
    &:hover {
      opacity: 1;
      transition: ease-in-out 350ms;
    }
  }
}
